import { NEWS_FEATURE } from '@propertypal/shared/src/constants/content-hub';
import axios, { API_URL } from '@propertypal/shared/src/services/axios';
import strapi, { STRAPI_URL } from '@propertypal/shared/src/services/axios.strapi';
import { HomepageTakeover } from '@propertypal/shared/src/types/homepageTakeover';
import { Property } from '@propertypal/shared/src/types/property';
import { StrapiResponse } from '@propertypal/shared/src/types/strapi/generic';
import { ContentHubArticle, ConvertedContentHubArticle } from '@propertypal/shared/src/types/strapi/overrides';
import { SuperFeatureProperty } from '@propertypal/shared/src/types/superFeature';
import { findRichText } from '@propertypal/shared/src/utils/content-hub';
import hasFeature from '@propertypal/shared/src/utils/featureFlag';
import platform from '@propertypal/shared/src/utils/platform';
import pick from 'lodash/pick';
import Head from 'next/head';
import { GetServerSideProps } from 'next/types';
import React, { FunctionComponent } from 'react';
import HomePage from '../components/home/HomePage';
import generateMetaTags from '../components/layout/MetaTags';
import cachedRequest from '../services/cachedRequest';
import { getCfIpCountry, requireAuthToken } from '../utils/auth';

interface Props {
  superfeatures: SuperFeatureProperty[];
  recent: Property[];
  popular: Property[];
  takeover: HomepageTakeover | null;
  articles: ConvertedContentHubArticle[] | null;
  newsAndArticles: boolean;
}

const metaData = {
  title: `${platform.title} - Find Property For Sale And Rent In Ireland And Northern Ireland`,
  // eslint-disable-next-line max-len
  description: `Browse Through More Than 60,000 Properties For Sale And Rent In Ireland And Northern Ireland. ${platform.title} Is Your Best Friend In Property.`,
  url: '',
};

const simplifyProperty = (property: Property) => {
  return pick(property, [
    'account',
    'agentLogos',
    'images',
    'path',
    'id',
    'displayAddress',
    'price',
    'epc',
    'ber',
    'logos',
    'addressLine1',
    'town',
    'alternativeCurrencyPrice',
    'listingTime',
    'agents',
    'numBedrooms',
    'numBathrooms',
    'numReceptionRooms',
    'viewsInLast7Days',
  ]);
};

export const getServerSideProps: GetServerSideProps = requireAuthToken(async (ctx, store) => {
  const result = await axios(
    {
      method: 'GET',
      url: `${API_URL}/homepage`,
    },
    store.dispatch,
    ctx,
  );

  const news = NEWS_FEATURE
    ? await strapi<StrapiResponse<ContentHubArticle[]>>({
        method: 'GET',
        url: `${STRAPI_URL}/articles`,
        params: {
          populate: 'poster,tabletPoster,mobilePoster,subCategories,content',
          'filters[$and][0][$or][0][hidden][$eq]': 'false',
          'filters[$and][0][$or][1][hidden][$null]': 'true',
          'filters[$and][1][subCategories][articleCategory][$in]': 'news-and-analysis',
          sort: 'publishedAt:desc',
          'pagination[pageSize]': 3,
        },
      })
    : null;

  const articles =
    news?.data?.data?.map((article) => ({
      ...article,
      attributes: {
        ...article.attributes,
        content: article.attributes.excerpt || findRichText(article.attributes.content),
      },
    })) || null;

  const superfeatures = result.data.superfeatures.sort(() => Math.random() - 0.5);

  const popular = result.data.popular.sort(() => Math.random() - 0.5).map(simplifyProperty);

  const siteMapLinks =
    getCfIpCountry(ctx) === 'IE'
      ? await cachedRequest('/site-map/footer/irl', ctx, 6)
      : await cachedRequest('/site-map/footer/nir', ctx, 6);

  let takeover: Props['takeover'] = null;

  if (result.data.takeover) {
    // simplify listing property from takeover in result
    takeover = {
      ...result.data.takeover,
      // Any changes here should be reflected in the HomepageTakeover["listing"] type
      listing: pick(result.data.takeover.listing, [
        'listingLogos',
        'path',
        'price',
        'showHomeOpeningTime',
        'images',
        'displayAddress',
        'displayAddressLine1',
      ]),
    };
  }

  return {
    props: {
      superfeatures,
      recent: result.data.recent.map(simplifyProperty),
      popular,
      siteMapLinks,
      takeover,
      articles,
      newsAndArticles: hasFeature(result.headers, 'sc-29605-news'),
    },
  };
});

const Home: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Head>{generateMetaTags(metaData)}</Head>

      <HomePage {...props} />
    </>
  );
};

export default Home;
